import React, { useState, useContext } from "react";
import { CssTextField, Estilos } from "../../Styles";
import { helper } from "../helper/helper";
import { Button } from "@material-ui/core";
import { UsuarioContext } from "../context/UsuarioContext";
import useLoader from "../loader/useLoader";
import { AlertContext } from "../context/AlertContext";

const Logeo = ({ cambiarEstado }) => {
    const classes = Estilos();
    const [loader, showLoader, hideLoader] = useLoader();

    const { stateAlert } = useContext(AlertContext);

    const [usuario, setUsuario] = useState({
        correo: "",
        clave: "",
        colorbackgr: "black",
        colortext: "white",
    });

    const { setUser } = useContext(UsuarioContext);

    //FUNCION REGISTRO API
    const validarSesion = async (e) => {
        e.preventDefault();
        showLoader();

        try {
            const res = await helper.post("sesion/validarsesion", usuario);
            if (res.data.status !== 200) {
                hideLoader();
                stateAlert("nologin", res.data.message);
            } else {
                await localStorage.setItem("token", res.data.token);
                setUser(res.data.usuario);
                cambiarEstado();
                helper.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${localStorage.getItem("token")}`;
                hideLoader();
                stateAlert("login", res.data.message);
            }
        } catch (error) {
            localStorage.removeItem("token");
            hideLoader();
            stateAlert("error", error.response.data.message);
        }
    };

    const handleChange = (e) => {
        setUsuario((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value.trim(),
        }));
    };

    return (
        <>
            <div className={classes.container} color="white">
                {loader}

                <div className={classes.paper}>
                    <h1 className={classes.loginTitle}>SISTEMA DE AUDITORÍA</h1>
                    <form onSubmit={validarSesion} className={classes.loginForm}>
                        <label>
                            {/* Correo / RUC */}
                            <CssTextField
                                label="Correo / RUC"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="correo"
                                value={usuario.correo}
                                autoFocus
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            {/* Contraseña */}
                            <CssTextField
                                label="Contraseña"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="clave"
                                type="password"
                                value={usuario.clave}
                                onChange={handleChange}
                            />
                        </label>
                        <Button
                            variant="contained"
                            className={classes.submit}
                            type="submit"
                        >
                            INGRESAR
                        </Button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Logeo;
