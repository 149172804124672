import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import useStyles from '../../Styles';

const Navbar = ({ open, cambiarOpen }) => {
	const classes = useStyles();
	return (
		<AppBar
			position="fixed"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open,
			})}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={cambiarOpen}
					edge="start"
					className={clsx(classes.menuButton, open && classes.hide)}
				>
					<MenuIcon />
				</IconButton>
				<Typography variant="h6" style={{ fontSize: '17px' }} noWrap>
					SISTEMA DE AUDITORÍA LOT INTERNACIONAL
				</Typography>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
